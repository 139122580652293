<script setup>
const props = defineProps({ config: { type: Object, default: () => {} } });

const leftMenuItems = computed(() => {
  return props.config.items?.slice(0, 3) || [];
});
const rightMenuItems = computed(() => {
  return props.config.items?.slice(3) || [];
});

const { isOpen: isMobileNavOpen, toggle } = useMobileNavigation();
</script>

<template>
  <nav v-editable="config" class="relative bg-white text-black">
    <Container>
      <div class="mt-5 justify-center hidden lg:flex xl:hidden">
        <NuxtLink href="/">
          <NuxtImg
            src="/logo.svg"
            alt="Smartstone Logo"
            width="182px"
            height="26px"
          />
        </NuxtLink>
      </div>
      <div class="items-center justify-between hidden lg:flex">
        <ul class="flex gap-4 xl:gap-5 items-center">
          <HeaderMenuItem
            v-for="(item, index) in leftMenuItems"
            :key="item._uid"
            :parent_link="item.parent_link"
            :parent_text="item.parent_text"
            :mega_menu="item.mega_menu"
            :class="{ 'lg:-ml-2.5': index === 0 }"
          />
        </ul>
        <div class="shrink-0 hidden xl:block">
          <NuxtLink href="/">
            <NuxtImg
              src="/logo.svg"
              alt="Smartstone Logo"
              width="182px"
              height="26px"
            />
          </NuxtLink>
        </div>
        <ul class="flex gap-4 xl:gap-5 items-center">
          <HeaderMenuItem
            v-for="item in rightMenuItems"
            :key="item._uid"
            :parent_link="item.parent_link"
            :parent_text="item.parent_text"
            :mega_menu="item.mega_menu"
          />
          <li>
            <HeaderCartButton />
          </li>
        </ul>
      </div>
    </Container>

    <div class="lg:hidden">
      <Container>
        <div class="flex py-2.5 justify-between items-center">
          <NuxtLink href="/">
            <NuxtImg
              src="/logo.svg"
              alt="Smartstone Logo"
              width="182px"
              height="26px"
            />
          </NuxtLink>
          <div class="flex gap-2.5">
            <HeaderCartButton />
            <NuxtImg
              width="24px"
              height="24px"
              src="/icon/menu.svg"
              alt="Menu icon"
              class="cursor-pointer"
              @click="toggle"
            />
          </div>
        </div>
      </Container>

      <div :class="{ hidden: !isMobileNavOpen }">
        <HeaderMobileMenuItem
          v-for="item in config.items"
          :key="item._uid"
          :parent_link="item.parent_link"
          :parent_text="item.parent_text"
          :mega_menu="item.mega_menu"
        />
      </div>
    </div>
  </nav>
</template>
