<script setup>
const props = defineProps({
  parent_text: { type: String, default: null },
  parent_link: { type: Object, default: () => ({}) },
  mega_menu: { type: Array, default: () => [] },
});

const { toggle } = useMobileNavigation();
const router = useRouter();

const isOpen = ref(false);
const anchorRef = ref(null);
const accordionRef = ref(null);

const toggleAccordion = () => {
  if (props.mega_menu[0]) {
    isOpen.value = !isOpen.value;
  } else {
    toggle();
    props.parent_link?.url && router.push(props.parent_link?.url);
  }
};

onClickOutside(accordionRef, (event) => {
  if (isOpen.value && event.target !== anchorRef?.value) {
    toggleAccordion();
  }
});
</script>

<template>
  <Container
    class="border-b border-b-[#999] cursor-pointer py-5"
    :class="{
      'bg-black': isOpen,
      'bg-[#FAF7F4]': !isOpen,
    }"
  >
    <div
      ref="anchorRef"
      class="flex justify-between items-center self-stretch"
      @click="toggleAccordion"
    >
      <div>
        <BlokLink
          :blok="mega_menu[0] ? null : parent_link"
          class="text-lg"
          :class="{ 'text-white font-helvetica-bold': isOpen }"
        >
          {{ parent_text }}
        </BlokLink>
      </div>
      <div v-if="mega_menu[0]">
        <NuxtImg
          v-if="isOpen"
          width="32px"
          height="32px"
          src="/icon/chevron-up-white.svg"
          alt="Chevron up"
        />
        <NuxtImg
          v-else
          width="32px"
          height="32px"
          src="/icon/chevron-down.svg"
          alt="Chevron down"
        />
      </div>
    </div>
  </Container>

  <div ref="accordionRef" class="bg-white" :class="{ hidden: !isOpen }">
    <div v-for="group in mega_menu[0]?.columns" :key="group._uid">
      <HeaderMobileMenuColumn
        v-for="column in group.columns"
        :key="column._uid"
        :heading="column.heading"
        :links="column.links"
        :on-navigation="toggleAccordion"
      />
    </div>
  </div>
</template>
