<script setup>
const props = defineProps({
  blok: {
    type: Object,
    default: () => ({}),
  },
  active: {
    type: Boolean,
    default: false,
  },
});

const hasFeatured = computed(() => {
  return props.blok.featured_text || props.blok.featured_image?.filename;
});
</script>

<template>
  <Container
    class="absolute left-0 top-[120px] bg-[#FDFAF9] shadow-xl z-30"
    :class="{
      'lg:bg-gradient-to-r lg:from-[#FDFAF9] lg:from-75% lg:to-[#AFA39D] lg:to-75%':
        hasFeatured,
    }"
  >
    <div
      class="flex-row xl:flex-row justify-between"
      :class="active ? 'flex' : 'hidden'"
    >
      <div class="py-[60px] flex items-start gap-5 self-stretch">
        <div
          v-for="group in blok?.columns"
          :key="group._uid"
          class="flex flex-col w-[350px] gap-5"
        >
          <div class="flex gap-2.5">
            <div class="text-xl font-helvetica-bold leading-7 text-black">
              {{ group.heading || "&nbsp;" }}
            </div>
          </div>
          <div class="flex">
            <HeaderMegaMenuColumn
              v-for="column in group?.columns"
              :key="column._uid"
              v-bind="column"
            />
          </div>
        </div>
      </div>
      <HeaderMegaMenuFeatured v-if="hasFeatured" v-bind="blok" />
    </div>
  </Container>
</template>
