<script setup lang="ts">
interface Props {
  class?: string;
  blok?: {
    alt: string;
    fieldtype: string;
    filename: string;
    id: number;
    is_external_url: boolean;
    name: string;
    source: string;
    title: string;
  };
}
const props = withDefaults(defineProps<Props>(), {
  blok: undefined,
  class: "",
});
</script>

<template>
  <img
    v-if="props?.blok?.filename"
    :class="props?.class"
    :src="props?.blok?.filename"
    :alt="props?.blok?.alt"
  />
</template>
