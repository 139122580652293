<script setup lang="ts">
import type { ProductPage } from "@/types";
const emit = defineEmits(["remove"]);
const { item } = defineProps<{ item: ProductPage; slug: string }>();

const { closeCart } = useCart();

const priceName = useProductPriceName(item.price);
const priceColor = await useProductPriceColor(priceName);

const handleRemove = () => emit("remove");
</script>

<template>
  <div class="p-2.5 bg-white flex self-stretch gap-2.5 lg:gap-4">
    <NuxtLink :href="`/${slug}`" @click="closeCart">
      <Image
        :blok="{
          image: item.swatchImage,
        }"
        class="w-[88px] lg:w-[127px] h-[110px] lg:h-[158px] flex-shrink-0"
      />
    </NuxtLink>

    <NuxtLink class="flex flex-1" :href="`/${slug}`" @click="closeCart">
      <div
        class="flex flex-col justify-center flex-1 py-0 lg:py-2.5 gap-0 lg:gap-4"
      >
        <div class="flex flex-col gap-2.5 self-stretch">
          <div class="flex gap-2.5 justify-between">
            <div class="font-helvetica-bold">{{ item.name }}</div>
            <IconCloseCircled
              class="h-6 w-6 cursor-pointer flex-shrink-0 hidden"
              @click="handleRemove"
            />
          </div>
          <RichText
            v-if="item.summary"
            v-editable="item"
            :content="item.summary"
            class="font-calson text-sm"
          />
        </div>
        <div class="flex gap-[5px] md:gap-2.5 flex-wrap leading-none">
          <span class="py-[5px] text-[13px]">{{ item.type }}</span>
          <span class="py-[5px] text-[13px]">{{ item.use }}</span>
          <span class="py-[5px] text-[13px]">{{ item.backgroundColor }}</span>
          <div class="py-[5px] flex gap-[5px]">
            <span
              class="h-2.5 w-2.5 rounded-full overflow-hidden"
              :style="{ backgroundColor: priceColor ?? 'inherit' }"
            />
            <span class="text-[13px]">{{ priceName }}</span>
          </div>
        </div>
      </div>
    </NuxtLink>

    <IconCloseCircled
      class="h-6 w-6 cursor-pointer flex-shrink-0"
      @click="handleRemove"
    />
  </div>
</template>
