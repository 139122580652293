<script setup>
const props = defineProps({
  heading: { type: String, required: true },
  links: { type: Array, default: () => [] },
  onNavigation: { type: Function, required: true },
});

const { toggle } = useMobileNavigation();

const isOpen = ref(false);
const linksRef = ref(null);
const headingRef = ref(null);

const toggleLinks = () => {
  isOpen.value = !isOpen.value;
};

onClickOutside(linksRef, (event) => {
  if (isOpen.value && event.target !== headingRef?.value) {
    toggleLinks();
  }
});

const onLinkClick = () => {
  props.onNavigation();
  toggle();
};
</script>

<template>
  <Container :class="{ 'border-b-[#999] border-b': !isOpen }">
    <div
      ref="headingRef"
      class="flex py-5 pl-2.5 items-center justify-between cursor-pointer"
      @click="toggleLinks"
    >
      <div class="text-lg" :class="{ 'font-helvetica-bold': isOpen }">
        {{ heading }}
      </div>
      <div>
        <NuxtImg
          v-if="isOpen"
          width="32px"
          height="32px"
          src="/icon/chevron-up.svg"
          alt="Chevron up"
        />
        <NuxtImg
          v-else
          width="32px"
          height="32px"
          src="/icon/chevron-right.svg"
          alt="Chevron right"
        />
      </div>
    </div>
  </Container>

  <Container>
    <div ref="linksRef" :class="{ hidden: !isOpen }">
      <BlokLink
        v-for="link in links"
        :key="link._uid"
        class="flex justify-between py-2.5 pl-2.5 text-[#666]"
        :blok="link.url"
        :is-form="link.isForm"
        @click="onLinkClick"
      >
        {{ link.text }}
        <NuxtImg
          width="32px"
          height="32px"
          src="/icon/chevron-right.svg"
          alt="Chevron right"
        />
      </BlokLink>
    </div>
  </Container>
</template>
