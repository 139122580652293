<script lang="ts" setup>
const props = defineProps({ config: { type: Object, default: () => ({}) } });
const { open } = useFormToggle();

const handleClick = () => {
  if (props.config && props.config?.heading_link?.id) {
    closePopup();
    open(props.config.heading_link.id);
  }
};

const showPopup = useCookie<boolean>("_show_popup", {
  default: () => true,
  expires: new Date(Date.now() + 86400 * 1000 * 3),
});

const closePopup = () => {
  showPopup.value = false;
};
</script>

<template>
  <div
    v-if="showPopup"
    class="fixed bottom-0 right-0 max-sm:h-1/6 3xl:h-1/3 max-sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 2xl:w-1/3 3xl:w-1/4 max-sm:p-2 3xl:p-2 4xl:p-5 p-4 z-50 overflow-visible bg-[#3A3633] bg-opacity-80 text-white"
  >
    <IconClose
      class="fixed right-5 max-sm:right-2 md:w-10 md:h-10 cursor-pointer"
      :color="'#fff'"
      @click="closePopup"
    />
    <div class="mt-10 mx-5 max-sm:mx-5 max-sm:text-center">
      <h2 class="font-helvetica-light text-[25px] lg:text-[33px] my-2">
        <span class="max-sm:hidden">{{ $props?.config?.heading }}</span>
        <NuxtLink href="#" class="md:hidden" @click.prevent="handleClick">
          {{ $props?.config?.heading }}
        </NuxtLink>
      </h2>
      <p class="my-5 max-sm:hidden 4xl:my-0">
        {{ $props?.config?.description }}
      </p>
      <div
        v-if="$props?.config?.links?.length"
        class="flex flex-col items-start max-sm:hidden"
      >
        <BlokLink
          v-for="blok in $props?.config?.links"
          :key="blok._uid"
          v-editable="blok"
          :blok="blok.url"
          :is-form="blok.isForm"
          :class="'text-white border-b-[0.5px] w-full py-3 space-x-3'"
          @link-clicked="closePopup"
        >
          <span class="float-left">{{ blok.text }}</span>
          <NuxtImg
            width="24px"
            height="24px"
            class="float-right"
            src="/icon/chevron-right-white.svg"
            alt="Chevron right"
          />
        </BlokLink>
      </div>
    </div>
  </div>
</template>
