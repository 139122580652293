<script setup>
const props = defineProps({
  parent_text: { type: String, default: null },
  parent_link: { type: Object, default: () => ({}) },
  mega_menu: { type: Array, default: () => [] },
});

const route = useRoute();

const isActive = ref(false);
const itemRef = ref();

const onClick = () => {
  if ((props.mega_menu?.length || 0) > 0) {
    isActive.value = !isActive.value;
  }
};

onClickOutside(itemRef, () => {
  if (isActive.value) {
    isActive.value = false;
  }
});

watch(
  () => route.path,
  () => {
    if (isActive.value) {
      isActive.value = false;
    }
  },
);
</script>

<template>
  <li ref="itemRef" class="py-3 2xl:py-5">
    <BlokLink
      :blok="mega_menu[0] ? null : parent_link"
      class="uppercase text-base p-[10px] h-20 flex items-center tracking-[0.1px] hover:font-helvetica-bold"
      :class="isActive ? 'font-helvetica-bold' : ''"
      @click="onClick"
    >
      {{ parent_text }}
    </BlokLink>

    <HeaderMegaMenu
      v-if="mega_menu[0]"
      :blok="mega_menu[0]"
      :active="isActive"
    />
  </li>
</template>
