<script lang="ts" setup>
const { openCart } = useCart();
</script>

<template>
  <div>
    <button
      type="button"
      class="lg:flex gap-2 xl:gap-[10px] px-4 xl:px-8 py-2.5 xl:py-5 bg-[#FAF7F4] rounded-full hidden"
      @click="openCart"
    >
      My favourites
      <NuxtImg
        width="24px"
        height="24px"
        src="/icon/favourites.svg"
        alt="Favorites Icon"
      />
    </button>
    <NuxtImg
      width="24px"
      height="24px"
      src="/icon/favourites.svg"
      alt="Favorites Icon"
      class="lg:hidden"
      @click="openCart"
    />
  </div>
</template>
