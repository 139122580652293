<script lang="ts" setup>
import type { Form, Page } from "@/types";

const props = defineProps<{ forms: Page<Form>[] }>();

const { activeFormId, close } = useFormToggle();

const formContent = computed(() => {
  if (!activeFormId.value) return;

  const formPage = props.forms.find((item) => item.uuid === activeFormId.value);
  return formPage?.content;
});

const isOpen = computed({
  get() {
    return !!formContent.value;
  },
  set(val) {
    if (!val) {
      close();
    }
  },
});
</script>

<template>
  <div>
    <UModal
      v-model="isOpen"
      :ui="{
        width: 'md:max-w-[1240px] max-w-[375px]',
        padding: 'p-0',
        rounded: 'rounded-none',
      }"
    >
      <GenericForm v-if="formContent" :blok="formContent" @close="close" />
    </UModal>
  </div>
</template>
