<script lang="ts" setup>
import type { StoryBlokVersion } from "@/types";

const header = await usePage("config/header");
const footer = await usePage("config/footer");
const popup = await usePage("config/side-popup");

const config = useRuntimeConfig();

const apiInstance = useStoryblokApi();
const data = await apiInstance.get("cdn/stories", {
  content_type: "form",
  starts_with: "config/",
  version: config.public?.storyblok?.version as StoryBlokVersion,
});

const forms = data?.data?.stories;
</script>

<template>
  <div class="relative">
    <NuxtLoadingIndicator />
    <Header v-if="header.content" :config="header.content" />
    <div class="flex flex-col justify-center bg-[#f9f9f9]">
      <slot></slot>
      <Cart />
    </div>
    <ClientOnly><SidePopup :config="popup.content" /></ClientOnly>
    <Footer :footer="footer?.content" />
    <Forms v-if="forms" :forms="forms" />
  </div>
</template>
