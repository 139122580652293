<script setup lang="ts">
const preventClose = ref<boolean>(false);
const {
  products,
  removeItem,
  isLoading,
  isCartOpen,
  closeCart,
  initCart,
  fetchProducts,
} = useCart();

const { open: openForm, activeFormId } = useFormToggle();

onMounted(() => {
  initCart();
  fetchProducts();
});

const handleShare = () => {
  if (products.value.length === 0) return;

  const permalink =
    window.location.origin +
    "/wishlist?product=" +
    products.value.map(({ uuid }) => uuid).join(",");

  const subject = "Here's a Smartstone surface I’d like to share with you";
  const body = `Hi, I found a Smartstone surface I want you to view. Go to: ${permalink}.`;

  window.location.href = `mailto:?subject=${subject}&body=${body}`;
};

watch(activeFormId, (formId) => {
  preventClose.value = !!formId;
});
</script>

<template>
  <USlideover
    v-model="isCartOpen"
    :ui="{
      width: 'sm:max-w-min',
      padding: 'p-0',
      rounded: 'rounded-none',
      container: 'items-center',
    }"
    :prevent-close="preventClose"
  >
    <div class="sm:w-[592px] bg-[#FDFAF9] px-2.5 py-5 lg:p-10 h-screen">
      <div class="flex flex-col gap-2.5 lg:gap-[21px] flex-auto h-full">
        <div class="flex justify-between">
          <h2 class="text-[25px] lg:text-[33px] font-helvetica-light">
            My Favourites
          </h2>
          <IconClose
            class="cursor-pointer focus-visible:outline-none focus:outline-none"
            tabindex="1"
            @click="closeCart"
          />
        </div>
        <div class="font-calson text-[19px] lg:font-helvetica lg:text-base">
          Compile your favourites here to share your Smartstone shortlist, order
          samples and find your nearest viewing location or stockist.
        </div>

        <div class="flex flex-col gap-[5px] overflow-y-scroll pe-0 lg:pe-5">
          <div v-if="isLoading" class="rounded-md p-4 max-w-sm w-full mx-auto">
            <div class="animate-pulse flex space-x-4">
              <div class="bg-slate-700 h-[110px] w-[88px]"></div>
              <div class="flex-1 space-y-6 py-1">
                <div class="h-2 bg-slate-700 rounded"></div>
                <div class="space-y-3">
                  <div class="grid grid-cols-3 gap-4">
                    <div class="h-2 bg-slate-700 rounded col-span-2"></div>
                    <div class="h-2 bg-slate-700 rounded col-span-1"></div>
                  </div>
                  <div class="h-2 bg-slate-700 rounded"></div>
                </div>
              </div>
            </div>
          </div>

          <CartItem
            v-for="product in products"
            :key="product.uuid"
            :slug="product.full_slug"
            :item="product.content"
            @remove="() => removeItem(product.uuid)"
          />

          <div
            v-if="products.length === 0 && !isLoading"
            class="p-2.5 bg-white flex self-stretch"
          >
            You do not have any favourites.
          </div>
        </div>

        <div class="py-5 flex flex-col gap-5 items-center">
          <button
            class="py-[19px] px-[49px] w-full bg-black text-white rounded-full"
            @click="
              () => {
                closeCart();
                openForm('bc763185-1dfc-4d4d-a172-2ba06f6ea695');
              }
            "
          >
            Order samples
          </button>
          <NuxtLink
            class="py-[19px] px-[49px] w-full bg-[#AFA39D] text-black rounded-full text-center"
            href="/locations/australia"
            @click="closeCart"
          >
            Find a viewing location
          </NuxtLink>
          <button class="flex gap-6" @click="handleShare">
            <span class="font-helvetica-bold">Share my favourites</span>
            <IconArrowRight class="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  </USlideover>
</template>

<style scoped>
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
